export const lowerFirstLetter = (str) => str.charAt(0).toLowerCase() + str.slice(1);

export const upperFirstLetter = (str) => str.charAt(0).toUpperCase() + str.slice(1);

export const stringToBoolean = (str) => {
	if (str.toLowerCase() === 'true') {
		return true;
	} else if (str.toLowerCase() === 'false' || str === null) {
		return false;
	} else {
		throw new Error('Неверный формат строки. Ожидается "true" или "false".');
	}
};

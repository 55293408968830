import React from 'react';
import Root from './Root';
import t from 'i18n';
import {
	FINANCE_MYCOMPANIES_PATH,
	FINANCE_ANALYTICS_PATH,
	FINANCE_OPERATIONS_PATH,
	FINANCE_REPORTS_PATH,
	FINANCE_SETTINGS_PATH,
	FINANCE_OPERATIONS_NEW_PATH,
} from './constants';

import CompaniesIcon from '@phosphor-icons/core/regular/buildings.svg';
import DashboardIcon from '@phosphor-icons/core/regular/chart-pie-slice.svg';
import OperationsIcon from '@phosphor-icons/core/regular/squares-four.svg';
import ReportsIcon from '@phosphor-icons/core/regular/cards-three.svg';
import SettingsIcon from '@phosphor-icons/core/regular/sliders-horizontal.svg';

/**
 * Конфигурация модуля.
 */

export default {
	/** Заголовок модуля */
	title: t('finance'),

	/** Короткий заголовок модуля */
	titleShort: t('finance'),

	/** Код модуля на латинице, обязателен */
	code: 'finance',

	/** Опциональная дополнительная функция проверки доступности модуля */
	enabled: (store) => store.model.Payment && store.model.Payment.INFO.READ && store.model.CashFlow && store.model.CashFlow.INFO.WRITE,

	/** Главный компонент с роутами и компонентами модуля */
	component: Root,

	/** Необязательный компонент для общего дашбоарда */
	// dashboardCard: FinanceCard,

	/** Меню модуля, может быть массивом или функцией (в аргументе передастся глобальный стор) */
	menu: () => [
		{
			type: 'item',
			label: t('contragent.myCompanies'),
			path: FINANCE_MYCOMPANIES_PATH,
			icon: () => <CompaniesIcon size='m' />,
		},
		{
			type: 'item',
			label: t('analytics.plural'),
			path: FINANCE_ANALYTICS_PATH,
			icon: () => <DashboardIcon size='m' />,
		},
		{
			type: 'item',
			label: t('operation.plural'),
			path: FINANCE_OPERATIONS_PATH,
			icon: () => <OperationsIcon size='m' />,
		},
		// {
		// 	type: 'item',
		// 	label: t('operation.plural'),
		// 	path: FINANCE_OPERATIONS_NEW_PATH,
		// 	icon: faReceipt,
		// },
		{
			type: 'item',
			label: t('reports.plural'),
			path: FINANCE_REPORTS_PATH,
			icon: () => <ReportsIcon size='m' />,
		},
		{
			type: 'item',
			label: t('settings'),
			path: FINANCE_SETTINGS_PATH,
			icon: () => <SettingsIcon size='m' />,
			enabled: (store) => store.model.PaymentDirection && store.model.PaymentDirection.INFO.WRITE,
		},
	],
};

import React from 'react';
import Root from './Root';
import t from 'i18n';
import { CONTRAGENTS_PATH, CONTRAGENTS_SETTINGS_PATH } from './constants';
import ContragentsIcon from '@phosphor-icons/core/regular/handshake.svg';
import SettingsIcon from '@phosphor-icons/core/regular/sliders-horizontal.svg';

export default {
	title: t('contragent.plural'),
	titleShort: t('contragent.titleShort'),
	code: 'contragents',
	enabled: (store) => store.model.Contragent && store.model.Contragent.INFO.WRITE,
	component: Root,
	menu: () => [
		{
			type: 'item',
			label: t('contragents.plural'),
			path: CONTRAGENTS_PATH,
			icon: () => <ContragentsIcon size='m' />,
			enabled: (store) => store.model.Contragent && store.model.Contragent.INFO.WRITE,
		},
		{
			type: 'item',
			label: t('settings'),
			path: CONTRAGENTS_SETTINGS_PATH,
			icon: () => <SettingsIcon size='m' />,
			enabled: (store) => store.model.ContragentType && store.model.ContragentType.INFO.WRITE,
		},
	],
};

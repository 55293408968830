import React from 'react';
import { observer, inject } from 'mobx-react';
import { observable } from 'mobx';
import NomenclatureFeatureValue from './NomenclatureFeatureValue';
import VariantPrices from './prices/VariantPrices';
import { Button, DeleteButton, triggerErrorNotification } from 'components';
import { Field, Form, Input } from '@smartplatform/ui';
import './nomenclature.scss';
import t from 'i18n';
import store from 'client/store';
import { upperFirstLetter } from 'client/tools';
import salesStore from '@pages/sales/salesStore';
import { ImageUploader } from '@features/ImageUploader';

@inject('productStore')
@observer
export default class NomenclatureCard extends React.Component {
	@observable isEdit = false;
	@observable imagePopup = false;
	@observable isNew = false;
	@observable isChangeName = false;
	@observable isChangeArticle = false;
	@observable error = null;

	constructor(props) {
		super(props);
		this.savedValue = new Map();
		this.isNew = this.props.nomenclature.id === undefined;
	}

	save = async () => {
		const { productStore, nomenclature } = this.props;
		this.error = '';
		for await (const [_, value] of this.savedValue) {
			value && value.save();
		}

		const isDuplicateName = (
			await store.model.Nomenclature.find({
				where: !!nomenclature.id ? { name: nomenclature.name, id: { neq: nomenclature.id } } : { name: nomenclature.name },
				fields: ['id'],
			})
		).length;

		const isDuplicateArticle = nomenclature.article
			? (
					await store.model.Nomenclature.find({
						where: !!nomenclature.id
							? { article: nomenclature.article, id: { neq: nomenclature.id } }
							: { article: nomenclature.article },
						fields: ['id'],
					})
				).length
			: false;

		if (!isDuplicateName && !isDuplicateArticle) {
			await this.props.nomenclature.save();
			await productStore.loadVariants();
			this.isNew = false;
			this.error = '';
		} else if (isDuplicateName) {
			this.error = t('nomenclature.isExistsName');
		} else if (isDuplicateArticle) {
			this.error = t('nomenclature.isExistsArticle');
		}

		this.isEdit = false;
	};

	delete = async () => {
		const { nomenclature, productStore } = this.props;
		if (!this.isNew) {
			nomenclature.product = null;
			await nomenclature.save();
		}
		await productStore.loadVariants();
	};

	onChange = (value) => {
		if (this.isNew) {
			this.savedValue.set(value.id, value);
		} else {
			value.save();
		}
	};

	onChangeImage = async (file, nomenclature) => {
		await nomenclature.save();
		await nomenclature.uploadFile('image', file);
	};

	onChangeNomenclatureProp = (prop) => (value) => {
		this.props.nomenclature[prop] = value;
		this[`isChange${upperFirstLetter(prop)}`] = true;
		if (!this.isNew) {
			this.save();
		}
	};

	onError = (error) => triggerErrorNotification(error);

	render() {
		const { nomenclature, productStore, number } = this.props;
		const { nomenclaturesFeatures } = productStore;

		return (
			<div className='product-variant'>
				<div className='product-nomenclature-wrapper'>
					<div className='image-wrapper'>
						<span>{number}</span>
						<div>
							{!this.isNew && (
								<>
									<label>
										{t('image')}
										<span className='is-required'>*</span>
									</label>
									<div className='variant-image' onClick={this.showImagePopup}>
										<ImageUploader
											onChange={(file) => this.onChangeImage(file, nomenclature)}
											record={nomenclature}
											property='image'
											maxSize={parseInt(salesStore.catalogMaxSizeImage) * 1024 * 1024}
											onError={this.onError}
										/>
									</div>
								</>
							)}
							<div className='wrapper-fields-nomenclature-card'>
								<Form record={nomenclature} noDelete noSave className='wrapper-fields-nomenclature-card'>
									<Field property='name' label={t('name')} isRequired>
										<Input onChange={this.onChangeNomenclatureProp('name')} />
									</Field>
									<Field property='article' label={t('article')}>
										<Input onChange={this.onChangeNomenclatureProp('article')} />
									</Field>
								</Form>
							</div>
						</div>
					</div>
					<div className='features'>
						{!this.isNew &&
							nomenclaturesFeatures.map((variantFeature) => (
								<div key={variantFeature.id} className='feature-field'>
									<label>{variantFeature.feature.name}</label>
									<NomenclatureFeatureValue
										nomenclature={nomenclature}
										feature={variantFeature.feature}
										onChange={this.onChange}
									/>
								</div>
							))}
					</div>
					{!this.isNew && <VariantPrices nomenclature={nomenclature} onChange={this.onChange} />}
					<div className='actions'>
						<DeleteButton
							onConfirm={this.delete}
							content={t('product.confirmDeleteVariant')}
							className='delete-variant'
						></DeleteButton>
						{(this.isNew || this.isEdit) && (
							<Button onClick={this.save} disabled={!nomenclature.name}>
								{t('save')}
							</Button>
						)}
					</div>
				</div>
				{this.error && <div className='error'>{this.error}</div>}
			</div>
		);
	}
}
